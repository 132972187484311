.aea-svg {
    width: 100%;
    height: 100%;
    max-height: 35px;
    max-width: 35px;
}

svg#svg-info {
    background-color: white;
    border-radius: 50px;
    padding: 7px;
    fill: #000000;
}

#svg-phone {
    fill: transparent;
}

#svg-user {
    max-width: 35px;
    max-height: 35px;
}




