html {
    font-size: 100%; /*default is 16px*/
}

body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px; /*fallback*/
    font-size: 1rem;
    height: 100vh;
    position: relative;
    background-color: #FAFAFA;
}

#root {
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
.h1 {
    font-size: 34px;
}

h2,
.h2 {
    font-size: 24px;
}

h3,
.h3 {
    font-weight: bold;
    font-size: 20px;
}

.font-size-alt {
    font-size: 14px;
}

.aea-caption {
    font-size: 12px;
}

input {
    font-family: inherit;
}

input::placeholder {
    color: #666666;
}

ol,
ul {
    padding-left: 0;
}

li {
    display: block;
}

a {
    font-size: 0.875rem;
    color: #006083;
    line-height: 2.625rem;
    text-decoration: none;
}

a:hover {
    color: #006083;
    text-decoration: none;
}

strong,
.strong-text {
    font-weight: 500;
}

.stronger-text,
.strong-text {
    color: #006083;
}

.stronger-text {
    font-weight: 700;
}

.has-separator + .has-separator {
    border-top: 0.5px solid #959595;
}
