:root {
    /*--toastify-toast-background: #FB6854;*/
    --toastify-color-success: #56C568
}

.app-toaster-container .Toastify__toast {
    border-radius: 15px;
}

.app-toaster-container .Toastify__toast-theme--colored.Toastify__toast--success .Toastify__toast-icon {
    width: 35px;
}

.app-toaster-container .Toastify__close-button {
    background: #006083;
    border-radius: 50px;
    padding: 5px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
}

.app-toaster-container .Toastify__close-button > svg {
    position: relative;
    left: 1px;
}