.code-digit-input {
    display: inline-block;
}

.otp-code-form__fieldSet {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 60px;
}

.otp-code-form__fieldSet .code-digit-input + .code-digit-input {
    margin-top: 0;
}

.code-verification__footer {
    color: #006083;
    background-color: #FFFFFF;
    width: 100%;
    position: absolute;
    bottom: -15px;
    padding: 15px;
}

.code-verification {
    height: 100%;
    position: relative;
}

.code-verification__footer__title {
    font-weight: bold;
}

@media only screen and (min-width: 992px) {
    .code-verification__footer {
        width: 100%;
        left: 0;
        padding-top: 30px;
    }

    .code-verification__footer {
        position: static;
    }
}